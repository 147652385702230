import React from 'react'
//import '../components/main.scss'


import Layout from '../components/layout'
import Front from './front'



const IndexPage = () => (
  <Layout articlelevel={100}>  
  <Front></Front>
  </Layout>
)




export default IndexPage
